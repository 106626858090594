import React, { Component } from 'react';
import style from './searchinput.module.scss';


class SearchInput extends Component {

	constructor(props) {
		super(props);
		this.textInput = React.createRef();
	}
	render() {
		if (this.props.focusState) {
			this.textInput.current.focus({preventScroll: true});
		}
		let { className } = this.props;
		className = (className||'').split(' ');
		className.push(style.searchInput);
		className = className.filter((item) => item);
		return (
			<div className={className.join(' ')}>
				<input onChange={this.props.changeHandler} ref={this.textInput} autoComplete="off" type="text" name={this.props.name} id={this.props.name} placeholder={this.props.placeholder} />
				<label htmlFor={this.props.name} >{this.props.label}</label>
			</div>
		)
	}
}

export default SearchInput;